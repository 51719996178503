import { createSlice } from "@reduxjs/toolkit";

const sourceSlice = createSlice({
  name: "source",
  initialState: {
    sources: [
      { id: "Parimatch", name: "Parimatch" },
      { id: "SportsRadar", name: "SportsRadar" },
      { id: "SportsApi", name: "SportsApi" },
    ],
    currentSource: null,
  },
  reducers: {
    setCurrentSource(state, action) {
      state.currentSource = action.payload;
    },
  },
});

export default sourceSlice.reducer;
export const { setCurrentSource } = sourceSlice.actions;
